.about {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.aboutTop {
  border: 2px solid red;
  width: 100%;
  height: 45%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.aboutBottom {
  display: flex;
  flex-direction: column;
  height: 55%;
  align-items: center;
  margin-top: 50px;
}

.aboutBottom p {
  width: 80rem;
}

.about .aboutBottom h1 {
  font-weight: 400;
  font-size: 7rem;
  color: black;
  height: 3rem;
  display: flex;
  justify-content: center;
  transform: translateY(-40px);
}
@media  (max-width:1500px){
  .aboutBottom p {
     width: 50rem;
  }
  .about .aboutBottom h1{
    font-size:4rem ;
  }
  
}
@media  (max-width:700px){
  .aboutBottom p {
     width: 25rem;
  }
  .about .aboutBottom h1{
    font-size:2rem ;
  }
  
}
